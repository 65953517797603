import 'tailwindcss/tailwind.css'
import { AppProps } from 'next/app'
import { AxiosProvider } from '../contexts/axios/AxiosContext'
import { EnvironmentProvider } from '../contexts/environment/EnvironmentContext'
import { GoogleReCaptchaProvider } from 'react-google-recaptcha-v3'
import { Hydrate, QueryClientProvider } from '@tanstack/react-query'
import { QueryClient } from '@tanstack/query-core'
import { ThemeProvider } from 'styled-components'
import { useState } from 'react'
import GlobalStyles from '../styles/GlobalStyles'
import config from '../config/config.json'
import useTheme from '../hooks/useTheme'

const App = ({ Component, pageProps }: AppProps & { themeValue?: string }) => {
    const { themeValue } = pageProps
    const [queryClient] = useState(() => {
        return new QueryClient()
    })

    useTheme(themeValue)

    return (
        <GoogleReCaptchaProvider reCaptchaKey={pageProps?.environmentConfig?.googleRecaptchaSiteKey}>
            <ThemeProvider theme={config.theme}>
                <AxiosProvider baseURL={pageProps?.environmentConfig?.apiUrl}>
                    <EnvironmentProvider config={pageProps?.environmentConfig}>
                        <QueryClientProvider client={queryClient}>
                            <Hydrate state={pageProps.dehydratedState}>
                                <GlobalStyles />
                                <Component {...pageProps} />
                            </Hydrate>
                        </QueryClientProvider>
                    </EnvironmentProvider>
                </AxiosProvider>
            </ThemeProvider>
        </GoogleReCaptchaProvider>
    )
}

export default App
