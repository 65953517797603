import { AxiosContextState, AxiosProviderProps } from './AxiosContext.types'
import React, { createContext, useMemo } from 'react'
import axiosLib, { AxiosInstance } from 'axios'

export const createAxiosInstance = baseURL => {
    if (!baseURL) {
        return
    }
    return axiosLib.create({ baseURL: `${baseURL}/api/v1` })
}

export const AxiosContext = createContext<AxiosContextState>({} as AxiosContextState)

export const AxiosProvider = ({ children, baseURL }: AxiosProviderProps) => {
    // INFO:
    // Global axios instance
    const axios = useMemo<AxiosInstance>(() => {
        return createAxiosInstance(baseURL)
    }, [baseURL])

    return <AxiosContext.Provider value={{ axios }}>{children}</AxiosContext.Provider>
}
