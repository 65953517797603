import { EnvironmentConfig } from './environmentConfig.types'
import getNextConfig from 'next/config'

const { publicRuntimeConfig } = getNextConfig()

const getConfig = () => {
    return publicRuntimeConfig
}

export const environmentConfig = getConfig() as EnvironmentConfig
