import { environmentConfig } from '../config/environment/environmentConfig'
import { useEffect } from 'react'

const useTheme = (themeValue: string) => {
    let themeName
    switch (themeValue) {
        case 'template_default':
            themeName = 'defaultTemplate'
            break
        case 'template_dark':
            themeName = 'darkTemplate'
            break
        case 'template_red':
            themeName = 'redTemplate'
            break
    }

    useEffect(() => {
        const linkElement = document.createElement('link')
        linkElement.setAttribute('rel', 'stylesheet')
        linkElement.setAttribute('href', `${environmentConfig.basePath}/theme/${themeName}.css`)
        document.head.appendChild(linkElement)
    }, [themeName])
}

export default useTheme
