import { css } from 'styled-components'

const GoogleTranslateStyles = css`
    // Google adds top: 40px to display own language bar witch is disabled for this web  
    body {
        top: 0 !important;
    }
    
    // Hide Google Translate default bar
    body > .skiptranslate {
        display: none !important;
    }

    // Style dynamic elements created by Google script
    #google_translate_element {
        height: 1.75rem;
        overflow: hidden;
    }
    #google_translate_element > .skiptranslate,
    #google_translate_element > .goog-te-gadget {
        padding: 0;
    }

    // Style Google dropdown input
    #google_translate_element > .goog-te-gadget .goog-te-combo {
        margin: 0 !important;
        padding: 0.375rem 0.75rem;
        font-size: 0.8125rem;
        line-height: 0.8125rem;
        color: black;
        border-radius: 0.125rem;
    }
}`

export default GoogleTranslateStyles
