import GoogleTranslateStyles from '../components/google-translate/GoogleTranslateStyles'
import TypographyStyles from './TypographyStyles'
import styled, { createGlobalStyle } from 'styled-components'
import tw from 'twin.macro'

const GlobalStyles = createGlobalStyle`
  body {
    position: relative;
  }

  #__next-route-announcer__ {
    ${tw`!sr-only`}
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
  
  ${GoogleTranslateStyles}
  
  ${TypographyStyles}
`

export const Container = styled.div`
    max-width: 90rem;
    margin: 0 auto;
    padding: 0rem 0;
`

export const HtmlTextContainerRoot = styled.div`
    ul {
        ${tw`list-disc pl-8`}
    }

    ol {
        ${tw`list-decimal pl-8`}
    }
`

export default GlobalStyles
