import { css } from 'styled-components'

const TypographyStyles = css`
    p {
        margin: 0 !important;
    }

    .text-h1,
    .text-h2,
    .text-h3,
    .text-h4 {
        margin: 0;
        font-style: normal;
        font-weight: 700;
        letter-spacing: -0.063rem;
    }

    .text-h1 {
        font-size: 3.75rem;
        line-height: 5.125rem;
    }

    .text-h2 {
        font-size: 3.125rem;
        line-height: 4.256rem;
        position: relative;
        top: -1.128rem;
        margin-bottom: 0.625rem;
    }

    .text-h3 {
        margin: 0.7rem 0;
        font-size: 2.5rem;
        line-height: 3.404rem;
    }

    .text-h4 {
        margin: 0.7rem 0;
        font-size: 1.563rem;
        line-height: 2.128rem;
    }

    .text-body-large,
    .text-body-normal,
    .text-body-small,
    .text-overline {
        font-style: normal;
        font-weight: 400;
    }

    .semibold {
        font-weight: 600;
    }

    .bold {
        font-weight: 700;
    }

    .text-body-large {
        font-size: 1.125rem;
        line-height: 2.188rem;

        .semibold {
            line-height: 1rem;
        }

        .bold {
            line-height: 1.563rem;
        }
    }

    .text-body-normal {
        font-size: 1rem;
        line-height: 2rem;
        letter-spacing: 0.004em;

        .bold {
            line-height: 1.25rem;
        }
    }

    .text-body-small {
        font-size: 0.875rem;
        line-height: 0.75rem;
        letter-spacing: 0.004em;

        .bold {
            line-height: 1.25rem;
        }
    }

    .text-overline-normal {
        font-size: 0.875rem;
        line-height: 1rem;
        text-transform: uppercase;

        .bold {
            line-height: 1rem;
            letter-spacing: 0.004em;
        }
    }

    /* Font styles for mobile devices width <= 700px  */
    @media only screen and (max-width: 700px) {
        .text-h1 {
            font-size: 2.25rem;
            line-height: 3.055rem;
        }

        .text-h2 {
            font-size: 1.85rem;
            line-height: 2.131rem;
            text-align: center;
        }

        .text-h3 {
            font-size: 1.6rem;
            line-height: 3.204rem;
        }

        .text-h4 {
            font-size: 1.4rem;
            line-height: 2rem;
        }

        .text-body-large {
            font-size: 1rem;
        }

        .text-body-normal {
            font-size: 0.88rem;
        }

        .text-body-small {
            font-size: 0.78rem;
        }
    }
`

export default TypographyStyles
